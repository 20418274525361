import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileState, CSVData } from '../dto/types';

const initialState: FileState = {
    csvFiles: [],
    keysSelection: [],
    downloadedFile: null,
};

const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        saveCSVFiles: (state, action: PayloadAction<CSVData[]>) => {
            state.csvFiles = action.payload;
        },
        saveDownloadedFile: (state, action: PayloadAction<Blob>) => {
            state.downloadedFile = action.payload;
        },
        saveKeysSelection: (state, action: PayloadAction<string[]>) => {
            state.keysSelection = action.payload;
        }
    },
});

export const { saveCSVFiles, saveDownloadedFile, saveKeysSelection } = fileSlice.actions;

export default fileSlice.reducer;