import React, { useState } from "react";
import { ListGroup, Form, Button } from "react-bootstrap";
import { Chip } from "@mui/material";
import { FaQuestionCircle } from "react-icons/fa";

interface KeyFieldSelectorProps {
  keyFields: string[];
  selectedKeyFields: string[];
  setSelectedKeyFields: React.Dispatch<React.SetStateAction<string[]>>;
}

const KeyFieldSelector: React.FC<KeyFieldSelectorProps> = ({
  keyFields,
  selectedKeyFields,
  setSelectedKeyFields,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSelect = (item: string) => {
    setSelectedKeyFields((prev: string[]) => {
      if (prev.includes(item)) {
        return prev.filter((i: string) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleClearSelected = () => {
    setSelectedKeyFields([]);
  };

  const filteredItems = keyFields.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className="key-field-selector p-3 mt-4"
      style={{
        borderRadius: "8px",
        border: "1px solid #ced4da",
        backgroundColor: "#f8f9fa",
        maxWidth: "100%",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Header section with title and tooltip */}
      <div
        className="d-flex justify-content-between align-items-center px-3 py-2"
        style={{
          backgroundColor: "#f0f0f0",
          borderRadius: "8px 8px 0 0",
          borderBottom: "1px solid #ced4da",
        }}
      >
        <h5 className="mb-0 d-flex align-items-center">
          Select key fields
          {/* Tooltip Icon */}
          <span className="tooltip-wrapper ms-2">
            <FaQuestionCircle
              size={20}
              color="#ced4da"
              style={{ marginLeft: "5px", cursor: "pointer" }}
            />
            <span className="custom-tooltip">
              Select a column that has a different value for each row, and which
              can be used to match rows between the first and second files.
              <br />
              <br />
              If you select more than one column, all of the columns selected
              will be used in combination as a unique value for matching.
            </span>
          </span>
        </h5>
      </div>

      {/* Content Section: Search, Key Fields, and Selected Items */}
      <div className="d-flex" style={{ padding: "20px" }}>
        {/* Left Section: Search and Key Fields */}
        <div className="w-50 me-4">
          {/* Search Field */}
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-2"
          />
          {/* List of Key Fields */}
          <ListGroup style={{ maxHeight: "250px", overflowY: "auto" }}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex align-items-center"
                >
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${index}`}
                    className="me-2"
                    checked={selectedKeyFields.includes(item)}
                    onChange={() => handleSelect(item)}
                  />
                  {item}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item>No results found</ListGroup.Item>
            )}
          </ListGroup>
        </div>

        {/* Vertical Divider */}
        <div
          className="vertical-divider"
          style={{
            width: "1px",
            backgroundColor: "#ced4da",
            height: "100%",
            margin: "0 20px",
          }}
        ></div>

        {/* Right Section: Selected Items */}
        <div className="w-50">
          <h6>Selected</h6>
          <div className="mb-2">
            {selectedKeyFields.length > 0 ? (
              selectedKeyFields.map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  onDelete={() => handleSelect(item)}
                  className="me-1 mb-1"
                />
              ))
            ) : (
              <p>No fields selected</p>
            )}
          </div>
          {/* Clear Selected Button */}
          <Button
            variant="outline-danger"
            onClick={handleClearSelected}
            disabled={selectedKeyFields.length === 0}
          >
            Clear selected
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KeyFieldSelector;
