import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { FiUploadCloud } from "react-icons/fi";
import {
  FaQuestionCircle,
  FaFileCsv,
  FaTimesCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import Papa from "papaparse";

interface DoubleFileUploadProps {
  setFile1Uploaded: React.Dispatch<React.SetStateAction<boolean>>;
  setFile2Uploaded: React.Dispatch<React.SetStateAction<boolean>>;
  setFile1: React.Dispatch<React.SetStateAction<File | null>>;
  setFile2: React.Dispatch<React.SetStateAction<File | null>>;
  file1: File | null;
  file2: File | null;
  setParsedFile1: React.Dispatch<React.SetStateAction<any[]>>;
  setParsedFile2: React.Dispatch<React.SetStateAction<any[]>>;
}

const DoubleFileUpload: React.FC<DoubleFileUploadProps> = ({
  setFile1Uploaded,
  setFile2Uploaded,
  setFile1,
  setFile2,
  file1,
  file2,
  setParsedFile1,
  setParsedFile2,
}) => {
  const fileInputRef1 = useRef<HTMLInputElement>(null);
  const fileInputRef2 = useRef<HTMLInputElement>(null);

  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const parseCSV = (
    file: File,
    setParsedData: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          console.error("Error parsing CSV:", results.errors);
        }
        setParsedData(results.data);
      },
    });
  };

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: (file: File | null) => void,
    setParsedFile: React.Dispatch<React.SetStateAction<any[]>>,
    setFileUploaded: (value: boolean) => void,
    setError: (value: boolean) => void
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileType = file.name.split(".").pop()?.toLowerCase();
      if (fileType === "csv") {
        setFile(file);
        setFileUploaded(true);
        setError(false);
        parseCSV(file, setParsedFile);

        event.target
          .closest(".file-upload-box")
          ?.classList.add("file-upload-success");
      } else {
        setFile(null);
        setFileUploaded(false);
        setError(true);
      }
    }
  };

  const handleBoxClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleRemoveFile = (
    setFile: (file: File | null) => void,
    setFileUploaded: (value: boolean) => void,
    setError: (value: boolean) => void
  ) => {
    setFile(null);
    setFileUploaded(false);
    setError(false);
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center mt-4 flex-column"
      style={{ maxWidth: "1200px" }}
    >
      <div className="d-flex justify-content-between" style={{ width: "100%" }}>
        {/* First File Upload Box */}
        <div
          className="d-flex flex-column align-items-center"
          style={{ width: "48%" }}
        >
          <div
            className={`file-upload-box d-flex flex-column justify-content-center align-items-center ${
              error1 ? "file-upload-error" : file1 ? "file-upload-success" : ""
            }`}
            style={{
              width: "100%",
              height: "180px",
              padding: "20px",
              border: error1
                ? "2px solid red"
                : file1
                ? "2px solid #00a6ce"
                : "2px solid #ced4da",
              borderRadius: "15px",
              textAlign: "center",
              backgroundColor: "#fff",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => handleBoxClick(fileInputRef1)}
          >
            {file1 ? (
              <div className="d-flex align-items-center justify-content-center">
                <FaFileCsv size={24} color="#00a6ce" />
                <span className="text-muted ml-2">{file1.name}</span>
                <FaTimesCircle
                  size={20}
                  color="#007bff"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFile(setFile1, setFile1Uploaded, setError1);
                  }}
                />
              </div>
            ) : (
              <>
                <FiUploadCloud size={40} color="#6c757d" />
                <p className="mt-2 text-muted">
                  Drop first document here or click to browse
                  <span className="tooltip-wrapper">
                    <FaQuestionCircle
                      size={16}
                      color="#ced4da"
                      style={{ marginLeft: "5px" }}
                    />
                    <span className="custom-tooltip">
                      Select a comma delimited file to begin your Compare
                    </span>
                  </span>
                </p>
              </>
            )}
            <input
              ref={fileInputRef1}
              type="file"
              style={{ display: "none" }}
              onChange={(event) =>
                handleFileSelect(
                  event,
                  setFile1,
                  setParsedFile1,
                  setFile1Uploaded,
                  setError1
                )
              }
            />
          </div>
          {error1 && (
            <div className="error-message d-flex align-items-center justify-content-center mt-2">
              <FaExclamationCircle size={16} color="red" />
              <span className="text-danger ml-2">
                Type of file not supported. Please select a CSV file.
              </span>
            </div>
          )}
        </div>

        {/* Second File Upload Box */}
        <div
          className="d-flex flex-column align-items-center"
          style={{ width: "48%" }}
        >
          <div
            className={`file-upload-box d-flex flex-column justify-content-center align-items-center ${
              error2 ? "file-upload-error" : file2 ? "file-upload-success" : ""
            }`}
            style={{
              width: "100%",
              height: "180px",
              padding: "20px",
              border: error2
                ? "2px solid red"
                : file2
                ? "2px solid #00a6ce"
                : "2px solid #ced4da",
              borderRadius: "15px",
              textAlign: "center",
              backgroundColor: "#fff",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => handleBoxClick(fileInputRef2)}
          >
            {file2 ? (
              <div className="d-flex align-items-center justify-content-center">
                <FaFileCsv size={24} color="#00a6ce" />
                <span className="text-muted ml-2">{file2.name}</span>
                <FaTimesCircle
                  size={20}
                  color="#007bff"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFile(setFile2, setFile2Uploaded, setError2);
                  }}
                />
              </div>
            ) : (
              <>
                <FiUploadCloud size={40} color="#6c757d" />
                <p className="mt-2 text-muted">
                  Drop second document here or click to browse
                  <span className="tooltip-wrapper">
                    <FaQuestionCircle
                      size={16}
                      color="#ced4da"
                      style={{ marginLeft: "5px" }}
                    />
                    <span className="custom-tooltip">
                      Select a comma delimited file to begin your Compare
                    </span>
                  </span>
                </p>
              </>
            )}
            <input
              ref={fileInputRef2}
              type="file"
              style={{ display: "none" }}
              onChange={(event) =>
                handleFileSelect(
                  event,
                  setFile2,
                  setParsedFile2,
                  setFile2Uploaded,
                  setError2
                )
              }
            />
          </div>
          {error2 && (
            <div className="error-message d-flex align-items-center justify-content-center mt-2">
              <FaExclamationCircle size={16} color="red" />
              <span className="text-danger ml-2">
                Type of file not supported. Please select a CSV file.
              </span>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default DoubleFileUpload;
