import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

import { ReactComponent as CompareLogo } from "../assets/CompareLogo.svg";

const ComparisonCard = ({ className }: { className?: string }) => {
  return (
    <Container className={`my-4 ${className}`}>
      <Card
        className="p-4"
        style={{
          borderRadius: "15px",
          backgroundColor: "#f8f9fa",
          textAlign: "center",
        }}
      >
        <Row className="justify-content-center align-items-center mb-3">
          <Col xs="auto">
            
            <CompareLogo width={50} height={50} className="d-inline-block align-top" />
          </Col>
          <Col xs="auto">
            <h5 className="mb-0" style={{ fontWeight: "bold" }}>
            Compare: Data Records Comparison System
            </h5>
          </Col>
        </Row>
        <Card.Text style={{ fontSize: "1rem", color: "#6c757d" }}>
          Dispatch Integration’s Compare is an automated, secure web app
          that quickly compares .csv and comma-delimited files to find dataset
          differences, saving hours of manual work.
        </Card.Text>
      </Card>
    </Container>
  );
};

export default ComparisonCard;
