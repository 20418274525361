import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import DoubleFileUpload from "./components/DoubleFileUpload";
import KeyFieldSelector from "./components/KeyFieldSelector";
import ComparisonCard from "./components/ComparisonCard";
import OutputTypeSelector from "./components/OutputTypeSelector";
import {
  compareCSVData,
  generateAndDownloadExcel,
} from "./utils/comparisonUtils";
import { extractHeadersFromCSV } from "./utils/extractHeaders";

const App: React.FC = () => {
  const [file1Uploaded, setFile1Uploaded] = useState<boolean>(false);
  const [file2Uploaded, setFile2Uploaded] = useState<boolean>(false);
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [parsedFile1, setParsedFile1] = useState<any[]>([]);
  const [parsedFile2, setParsedFile2] = useState<any[]>([]);
  const [showKeyFieldSelector, setShowKeyFieldSelector] =
    useState<boolean>(false);
  const [keyFields, setKeyFields] = useState<string[]>([]);
  const [selectedKeyFields, setSelectedKeyFields] = useState<string[]>([]);
  const [showOutputTypeSelector, setShowOutputTypeSelector] =
    useState<boolean>(false);
  const [selectedOutputOption, setSelectedOutputOption] =
    useState<string>("allRecords");
  const [selectedIncludeOriginal, setSelectedIncludeOriginal] =
    useState<boolean>(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const handleStartClick = async () => {
    if (file1 && file2) {
      const headers1 = await extractHeadersFromCSV(file1);
      const headers2 = await extractHeadersFromCSV(file2);

      setKeyFields(Array.from(new Set([...headers1, ...headers2])));
      setShowKeyFieldSelector(true);
    }
  };

  const handleProceedClick = () => {
    setShowOutputTypeSelector(true);
  };

  const handleSubmit = async () => {
    setSubmitDisabled(true);
  
    try {
      if (!file1 || !file2) {
        throw new Error("Both files must be uploaded.");
      }
  
      
      const file1Content = await file1.text();
      const file2Content = await file2.text();
  
      const payload = {
        input1: {
          dataType: "csvData",
          name: file1.name,
          content: file1Content,
          mimeType: "text/csv",
        },
        input2: {
          dataType: "csvData",
          name: file2.name,
          content: file2Content,
          mimeType: "text/csv",
        },
        fields: keyFields.reduce((acc: Record<string, boolean>, field: string) => {
          acc[field] = selectedKeyFields.includes(field);
          return acc;
        }, {}),
        respondAs: {
          responseType: "respondsAsExcel",
          mimeType: "application/vnd.ms-excel",
          fileName: "result.xlsx",
          allRecords: selectedOutputOption === "allRecords",
          includeInputDataInOutput: selectedIncludeOriginal,
        },
      };
  
      const response = await fetch("https://api.dispatchcompare.com/compare", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
          "Accept" : "application/vnd.ms-excel"
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to process the files");
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "result.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setSubmitDisabled(false);
    }
  };
  

  const handleStartNewCompare = () => {
    window.location.reload();
  };

  return (
    <div className="App vh-100 d-flex flex-column">
      
      <ComparisonCard className="common-width" />

      <div className="main-content common-width d-flex flex-column align-items-center justify-content-start mt-4">
        <DoubleFileUpload
          setFile1Uploaded={setFile1Uploaded}
          setFile2Uploaded={setFile2Uploaded}
          setFile1={setFile1}
          setFile2={setFile2}
          file1={file1}
          file2={file2}
          setParsedFile1={setParsedFile1}
          setParsedFile2={setParsedFile2}
        />

        {!showKeyFieldSelector && (
          <button
            className="start-button file-upload-container mt-4"
            onClick={handleStartClick}
            disabled={!file1Uploaded || !file2Uploaded}
          >
            Start
          </button>
        )}

        {showKeyFieldSelector && (
          <KeyFieldSelector
            keyFields={keyFields}
            selectedKeyFields={selectedKeyFields}
            setSelectedKeyFields={setSelectedKeyFields}
          />
        )}

        {showOutputTypeSelector && (
          <div className="output-type-box mt-4">
            <OutputTypeSelector
              selectedOutputOption={selectedOutputOption}
              setSelectedOutputOption={setSelectedOutputOption}
              selectedIncludeOriginal={selectedIncludeOriginal}
              setSelectedIncludeOriginal={setSelectedIncludeOriginal}
            />
          </div>
        )}

        {(showKeyFieldSelector || showOutputTypeSelector) && (
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-primary submit-button"
              style={{
                width: "300px",
                marginRight: "20px",
                opacity: isSubmitDisabled ? 0.5 : 1,
              }}
              onClick={
                showOutputTypeSelector ? handleSubmit : handleProceedClick
              }
              disabled={isSubmitDisabled}
            >
              {showOutputTypeSelector ? "Start" : "Proceed"}
            </button>

            {/* "Start New Compare" button */}
            {showOutputTypeSelector && (
              <button
                className="btn btn-primary submit-button"
                style={{ width: "300px" }}
                onClick={handleStartNewCompare}
              >
                Start New Compare
              </button>
            )}
          </div>
        )}

        {/* Terms and Conditions Section */}
        {showOutputTypeSelector && (
          <div className="terms-conditions mt-4">
            <p>
              By proceeding, you agree to our{" "}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>{" "}
              and acknowledge reading the{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
