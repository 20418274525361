import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import "../App.css";

interface OutputTypeSelectorProps {
  selectedOutputOption: string;
  setSelectedOutputOption: React.Dispatch<React.SetStateAction<string>>;
  selectedIncludeOriginal: boolean;
  setSelectedIncludeOriginal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OutputTypeSelector: React.FC<OutputTypeSelectorProps> = ({
  selectedOutputOption,
  setSelectedOutputOption,
  selectedIncludeOriginal,
  setSelectedIncludeOriginal,
}) => {
  return (
    <div className="selector-box">
      {/* Grey Navbar with Horizontal Line */}
      <div className="selector-header">
        <h5 className="mb-0 d-flex align-items-center">Select output type</h5>
      </div>

      {/* Buttons for Output Selection */}
      <div className="output-button-group mt-3">
        <div className="d-flex flex-column align-items-start">
          {/* Key Fields Label */}
          <h6 className="d-flex align-items-center">
            Key Fields
            <span className="tooltip-wrapper ms-2">
              <FaQuestionCircle size={14} color="#ced4da" />
              <span className="custom-tooltip">
                Choose whether you want the output to only show the records that
                were different between the two files, or all records, including
                those that matched.
              </span>
            </span>
          </h6>
          {/* Key Fields Button Group */}
          <ButtonGroup className="custom-toggle-button-group">
            <ToggleButton
              id="output-type-all-records"
              type="radio"
              className="custom-toggle-button"
              value="allRecords"
              checked={selectedOutputOption === "allRecords"}
              onChange={(e) => setSelectedOutputOption(e.currentTarget.value)}
            >
              All Records
            </ToggleButton>
            <ToggleButton
              id="output-type-only-differences"
              type="radio"
              className="custom-toggle-button"
              value="onlyDifferences"
              checked={selectedOutputOption === "onlyDifferences"}
              onChange={(e) => setSelectedOutputOption(e.currentTarget.value)}
            >
              Only Differences
            </ToggleButton>
          </ButtonGroup>
        </div>

        <div className="d-flex flex-column align-items-start mt-3">
          {/* Original Data Sheets Label */}
          <h6 className="d-flex align-items-center">
            Original Data Sheets
            <span className="tooltip-wrapper ms-2">
              <FaQuestionCircle size={14} color="#ced4da" />
              <span className="custom-tooltip">
                Choose whether you want the output file to include sheets
                showing the original data from the first and second files that
                you loaded.
              </span>
            </span>
          </h6>
          {/* Original Data Sheets Button Group */}
          <ButtonGroup className="custom-toggle-button-group">
            <ToggleButton
              id="include-original-include"
              type="radio"
              className="custom-toggle-button"
              value="include"
              checked={selectedIncludeOriginal}
              onChange={() => setSelectedIncludeOriginal(true)}
            >
              Include
            </ToggleButton>
            <ToggleButton
              id="include-original-exclude"
              type="radio"
              className="custom-toggle-button"
              value="exclude"
              checked={!selectedIncludeOriginal}
              onChange={() => setSelectedIncludeOriginal(false)}
            >
              Exclude
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default OutputTypeSelector;
